<script lang="ts">

    import { getContext } from 'svelte';
    import { navigate } from "svelte-routing";
    import RegisterPopup from './RegisterPopup.svelte';
    const { open } = getContext('simple-modal');

    open(RegisterPopup, {}, {}, {
        onClose: () => {
            navigate("/", { replace: false });
        }
    });

</script>

<div>

</div>

<style lang="scss">

</style>