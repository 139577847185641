<script lang="ts">

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	let mainContent: HTMLElement;

	let scrollY: number = 0;
	let innerHeight: number = 0;
	let visible: boolean = false;
	let started: boolean = false;

	import { onMount } from 'svelte';
  import { getContext } from 'svelte';
	import { quadInOut, quadOut, quintOut, sineOut } from 'svelte/easing';
	import { Router, Route, Link } from "svelte-routing";
	import { crossfade } from 'svelte/transition';
  import * as animateScroll from "svelte-scrollto";
	import Modal from 'svelte-simple-modal';
	import PulsingArrow from './PulsingArrow.svelte';
	import Trifold from './Trifold.svelte';
	import VideoPreview from './VideoPreview.svelte';
  import Navbar from './Navbar.svelte';
	import ResetPassword from './ResetPasswordPopup.svelte';

	onMount(async () => {
		started = true;
		await sleep(500);
		visible = true;
	});

	function blurIn(node, { duration }) {
		return {
			duration,
			css: t => {
				const eased = sineOut(t);
				return `
					filter: blur(${24 - (eased * 20)}px);
				`;
			}
		}
	}

	function fadefly(node, { duration, maxOpacity = 1 }) {
		return {
			duration,
			css: t => {
				const eased = quadInOut(t);
				const oeased = quadOut(t);

				return `
					transform: translate3d(0, ${(1 - eased) * -100}px, 0);
					opacity: ${oeased * maxOpacity};
				`;
			}
		};
	};

	const [send, receive] = crossfade({
		duration: d => Math.sqrt(d * 200),

		fallback(node, params) {
			const style = getComputedStyle(node);
			const transform = style.transform === 'none' ? '' : style.transform;

			return {
				duration: 600,
				easing: quadOut,
				css: t => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
			};
		}
	});

	$: safeInnerHeight = innerHeight ?? 700;
	$: safeScrollY = scrollY ?? 0;
	$: logoOpacity = (safeInnerHeight - safeScrollY) / (safeInnerHeight / 3);
	$: {
		if (logoOpacity < 0)
			logoOpacity = 0;
		else if (logoOpacity > 1)
			logoOpacity = 1;
	}
	$: scrollDownOpacity = (safeInnerHeight - (safeScrollY * 2)) / (safeInnerHeight * 1.5);
	$: if (scrollDownOpacity < 0)
		scrollDownOpacity = 0;

</script>

<svelte:window bind:scrollY bind:innerHeight />

<main class="cover main-normal">
    <Navbar fadefly={fadefly} />
    <div id="blackground" class="cover"></div>
    {#if started} <!-- this is required for transitions... apparently. -->
    <div id="background" class="cover" in:blurIn="{{ duration: 1000 }}"></div>
    {/if}
    <div id="logo-content">
        {#if visible}
            <img id="logo-splash" src="/img/btn-logo-light.png"
                alt="Beneath the Nexus logo"
                style="opacity: {logoOpacity}"
                in:fadefly="{{ duration: 1000 }}"
                on:click={() => animateScroll.scrollTo({element: "#content", duration: 1200, offset: -120})}
                />
        {/if}
        {#if visible}
            <p
                style="opacity: {scrollDownOpacity}; font-size: 16px;"
                in:fadefly="{{ duration: 1000, maxOpacity: scrollDownOpacity }}">
                (scroll down)
            </p>
            <PulsingArrow fadefly={fadefly} opacity={Math.min(1, scrollDownOpacity * 2)} />
        {/if}
    </div>
    {#if visible}
        <div id="content" bind:this={mainContent}>
            <VideoPreview />
            <div style="display: flex; align-items: center; justify-content: center; padding: 12px; margin: 24px; height: 24px; background: white; box-shadow: 0px 0px 16px 8px rgb(13 13 13 / 60%);">
                <p style="color: black"><strong>Beneath the Nexus</strong> is the bullet hell that's better with friends.</p>
            </div>
            <Trifold sleep={sleep} />
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 12px; margin: 12px; height: 100px;" >
                <p style="margin: 2px; text-shadow: 0px 0px 16px rgb(0 0 0 / 80%);">Coming soon.</p>
                <p style="margin: 2px; text-shadow: 0px 0px 16px rgb(0 0 0 / 80%)">&copy ACED Games LLC 2021</p>
            </div>
            <div class="bottom-logo-footer">
                <img class="bottom-logo" src="/img/btn-icon-light.png" alt="logo small" />
            </div>
        </div>
    {/if}
</main>


<style>
	.cover {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 100vh;
	}
	.main-normal {
		display: block;
	}
	main {
		text-align: center;
	}
	#blackground {
		top: 0;
		left: 0;
		position: fixed;
		transform: scale(1.2);
		background-color: black;
		z-index: -2;
	}
	#background {
		top: 0;
		left: 0;
		position: fixed;
		transform: scale(1.2);
		background: center / cover no-repeat;
		background-image: url("/img/page-background.png");
		filter: blur(4px);
		z-index: -1;
	}
	#logo-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-self: center;
		width: 100%;
		height: 100vh;
		min-height: 20%;
	}
	#logo-splash {
		top: 90px;
		height: 25vh;
		width: auto;
  		-webkit-filter: drop-shadow(5px 5px 5px #222);
  		filter: drop-shadow(5px 5px 5px #222);
		justify-self: center;
		position: sticky;
	}
	#logo-splash:hover {
		cursor: pointer;
	}
	#content {
		flex: 8;
		top: 25vh;
		position: sticky;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.bottom-logo-footer {
		margin-bottom: 48px;
	}
	.bottom-logo {
		width: 64px;
	}
</style>