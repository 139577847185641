<script lang="ts">

    import { getContext } from 'svelte';
    import { navigate } from "svelte-routing";
    import ForgotPasswordPopup from './ForgotPasswordPopup.svelte';
    const { open } = getContext('simple-modal');

    open(ForgotPasswordPopup, {}, {}, {
        onClose: () => {
            navigate("/", { replace: true });
        }
    });

</script>

<div>

</div>

<style lang="scss">

</style>