<script lang="ts">

    import { onMount } from "svelte";
    import { Circle } from 'svelte-loading-spinners'
    import { getContext } from 'svelte';
    
    const { close } = getContext('simple-modal');

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    let email: string;
    let password: string;
    let passwordConfirm: string;
    let testingKey: string;

    let emailError: string = "";
    let passwordError: string = "";
    let passwordConfirmError: string = "";
    let keyError: string = "";
    let genericError: string = "";

    let header: HTMLElement;
    let submitting:boolean = false;

    let registered:boolean = false;

    onMount(() => {
        let content = header.parentElement;
        let modalWindow = content.parentElement;
        let modalWindowWrap = modalWindow.parentElement;
        modalWindow.style.width = "30rem";
    });

    async function getEntitlement() {
        await sleep(5000);
        return fetch("/api/entitlements/get-new")
            .then(resp => resp.text());
    }
    
    function onSubmit() {
        if (submitting)
            return;

        // prevent double submit
        submitting = true;
        // clear out error texts
        emailError = "";
        passwordError = "";
        passwordConfirmError = "";
        keyError = "";
        genericError = "";
        
        if (!email) {
            // todo: status info
            emailError = "*Email is required"
            submitting = false;
        }
        else if (password.length < 7) {
            // todo: status info
            passwordError = "*Password must be at least 7 chars";
            submitting = false;
        }
        else if (password != passwordConfirm) {
            // todo: status info
            passwordError = "*Passwords must match";
            passwordConfirmError = "*Passwords must match";
            submitting = false;
        }
        else if (!testingKey) {
            // todo: status info
            keyError = "*Key is required";
            submitting = false;
        }
        else {
            let safeKey = encodeURIComponent(testingKey);
            let safeEmail = encodeURIComponent(email);
            let safePassword = encodeURIComponent(password);

            getEntitlement().then(token => {
                var formData = new FormData();
                formData.append("email", safeEmail);
                formData.append("password", safePassword);
                formData.append("betaKey", safeKey);
                formData.append("token", token);
                fetch("/api/account/register", { method: "POST", body: formData })
                    .then(resp => resp.text())
                    .then(data =>
                    {
                        // submission is done
                        submitting = false;

                        // ensure data has a value (404, 500, downtime, etc)
                        data = data || "Internal server error";

                        // lower case for simplicity
                        data = data.toLowerCase();

                        if (data.includes("error")) {
                            if (data.includes("internal")) {
                                genericError = "*Server error. Please try again later";
                            }
                            else if (data.includes("bad request")) {
                                genericError = "*Server error. Please try again later";
                            }
                            else if (data.includes("invalid email")) {
                                emailError = "*Invalid email";
                            }
                            else if (data.includes("email is already registered")) {
                                emailError = "*Email already registered";
                            }
                            else if (data.includes("password length")) {
                                passwordError = "*Password must be at least 7 chars";
                            }
                            else if (data.includes("beta key")) {
                                keyError = "*Key already used (or invalid)";
                            }
                        }
                        else {
                            registered = true;
                        }
                        console.log(data);
                    });
            });
        }
    }

</script>

<h1 bind:this={header}>Beneath the Nexus is in pre-alpha.</h1>
<p>
    In order to monitor stability, access is gated by <strong>Closed Testing keys.</strong>
    If you've got one of those, you can sign up here.
    Otherwise, stay tuned for info on the full release!
</p>
{#if !submitting && !registered}
<form action="/" method="POST">
    <label class="error-label" for="email" name="email-error">{emailError}</label>
    <input name="email" bind:value={email} type="email" placeholder="Email" autocomplete="off" />
    <label class="error-label" for="password" name="password-error">{passwordError}</label>
    <input name="password" bind:value={password} type="password" placeholder="Password" autocomplete="off" />
    <label class="error-label" for="password-confirm" name="password-confirm-error">{passwordConfirmError}</label>
    <input name="password-confirm" bind:value={passwordConfirm} type="password" placeholder="Confirm Password" autocomplete="off" />
    <label class="error-label" for="key" name="key-error">{keyError}</label>
    <input name="key" bind:value={testingKey} type="text" placeholder="Key (XXXX-XXXX-XXXX-XXXX)" autocomplete="off" />
    <label class="error-label" for="submit" name="general-error">{genericError}</label>
    <input name="submit" type="submit" value="Register" on:click|preventDefault={() => onSubmit() } />
</form>
{:else if submitting}
<div style="display: flex; align-items: center; justify-content: center">
    <Circle size="24" color="#131C18"></Circle>
</div>
{:else if registered}
<div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
    <hr style="border: none; border-top: 1px solid #00000033; width: 90%" />
    <p><strong>All set!</strong> Check your inbox <i>({email})</i> for a confirmation email.</p>
    <input on:click={() => close()} type="submit" value="✔">
</div>
{/if}

<style lang="scss">
    h1 {
        text-align: center;
        font-size: large;
    }
    p {
        text-align: center;
    }
    .error-label {
        align-self: flex-start;
        font-size: 14px;
        padding-left: 12px;
        color: red;
    }
    input {
        border: 1px solid #3c404422;
        margin: 14px;
        padding: 10px 12px;
        color: #3c4044;
        font-size: 16px;
        line-height: 26px;
        width: 90%;
        box-sizing: content-box;
    }
    input[type=submit] {
        cursor: pointer;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #3c404460;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #3c404460;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #3c404460;
    }
</style>