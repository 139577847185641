<script lang="ts">

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	export let url = "";

	let mainContent: HTMLElement;

	let scrollY: number = 0;
	let innerHeight: number = 0;
	let visible: boolean = false;
	let started: boolean = false;

	import { onMount } from 'svelte';
    import { getContext } from 'svelte';
	import { quadInOut, quadOut, quintOut, sineOut } from 'svelte/easing';
	import { Router, Route, Link } from "svelte-routing";
	import { crossfade } from 'svelte/transition';
    import * as animateScroll from "svelte-scrollto";
	import Modal from 'svelte-simple-modal';
	import PulsingArrow from './PulsingArrow.svelte';
	import Trifold from './Trifold.svelte';
	import VideoPreview from './VideoPreview.svelte';
    import Navbar from './Navbar.svelte';
	import ResetPassword from './ResetPassword.svelte';
	import ForgotPassword from './ForgotPassword.svelte';
	import Home from './Home.svelte';
	import Register from './Register.svelte';
	import Download from './Download.svelte';
	import Canonical from './Canonical.svelte';

	onMount(async () => {
		started = true;
		await sleep(500);
		visible = true;
	});

	function blurIn(node, { duration }) {
		return {
			duration,
			css: t => {
				const eased = sineOut(t);
				return `
					filter: blur(${24 - (eased * 20)}px);
				`;
			}
		}
	}

	function fadefly(node, { duration, maxOpacity = 1 }) {
		return {
			duration,
			css: t => {
				const eased = quadInOut(t);
				const oeased = quadOut(t);

				return `
					transform: translate3d(0, ${(1 - eased) * -100}px, 0);
					opacity: ${oeased * maxOpacity};
				`;
			}
		};
	};

	const [send, receive] = crossfade({
		duration: d => Math.sqrt(d * 200),

		fallback(node, params) {
			const style = getComputedStyle(node);
			const transform = style.transform === 'none' ? '' : style.transform;

			return {
				duration: 600,
				easing: quadOut,
				css: t => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
			};
		}
	});

	$: safeInnerHeight = innerHeight ?? 700;
	$: safeScrollY = scrollY ?? 0;
	$: logoOpacity = (safeInnerHeight - safeScrollY) / (safeInnerHeight / 3);
	$: {
		if (logoOpacity < 0)
			logoOpacity = 0;
		else if (logoOpacity > 1)
			logoOpacity = 1;
	}
	$: scrollDownOpacity = (safeInnerHeight - (safeScrollY * 2)) / (safeInnerHeight * 1.5);
	$: if (scrollDownOpacity < 0)
		scrollDownOpacity = 0;

</script>

<svelte:window bind:scrollY bind:innerHeight />

<Router url="{url}">
	<div>
		<Modal>
			<Home />
			<Route path="/">
				<Canonical href="https://playbtn.com/" />
			</Route>
			<Route path="/reset-password">
				<Canonical href="https://playbtn.com/reset-password" />
				<ResetPassword />
			</Route>
			<Route path="/forgot-password">
				<Canonical href="https://playbtn.com/forgot-password" />
				<ForgotPassword />
			</Route>
			<Route path="/register">
				<Canonical href="https://playbtn.com/register" />
				<Register />
			</Route>
			<Route path="/download">
				<Canonical href="https://playbtn.com/download" />
				<Download />
			</Route>
		</Modal>
	</div>
</Router>