<script lang="ts">
    import { onMount } from "svelte";
    import { expoInOut, expoOut } from "svelte/easing";
    import { getContext } from 'svelte';
    import { navigate } from "svelte-routing";

    import ProfileIcon from "../public/svg/person-outline.svg";
    import Register from "./Register.svelte";
    import Download from "./Download.svelte";
    import { fade, fly } from "svelte/transition";

    const { open } = getContext('simple-modal');

    export let fadefly;
    let started = false;

    let hoverState = "";
    let clickState = "home";

    let mobileExpanded = false;

    function navClicked(option: string) {
        if (clickState == option)
            return;

        if (option == "home")
            clickState = option;
        else if (option == "updates")
            window.open("https://aced.gg/", "_blank");
        else if (option == "discord")
            window.open("https://discord.com/invite/pgvJ59u", "_blank");
        else if (option == "sign up") {
            navigate("/register", { replace: false });
        }
        else if (option == "download") {
            navigate("/download", { replace: false });
        }

        // this is a little silly but kinda useful tbh
        if (mobileExpanded)
          toggleMobile();
    }

    function toggleMobile(state: boolean = null) {
        mobileExpanded = state ?? !mobileExpanded;
    }

    onMount(() => {
        started = true;
    });
</script>

{#if started}
    <nav class="navbar" in:fadefly="{{ duration: 1500 }}">
        <div class="navbar-widescreen">
            <div class="navbar-left">
                <div class="home-button">
                    <img class="icon" src="/img/btn-logo-light.png" alt="logo text" />
                </div>
                <div class={clickState == "home" ? "nav-option selected" : "nav-option"}>
                    <p on:click|preventDefault={() => navClicked("home") }>HOME</p>
                </div>
                <div class="nav-option">
                    <p on:click|preventDefault={() => navClicked("updates") }>UPDATES</p>
                </div>
                <div class="nav-option">
                    <p on:click|preventDefault={() => navClicked("discord") }>DISCORD</p>
                </div>
            </div>
            <div class="navbar-right">
                <div class="nav-option" style="margin: 24px">
                    <p style="padding: 12px" on:click|preventDefault={() => navClicked("sign up") }>Sign up</p>
                    <ProfileIcon height="20px" stroke="white" fill="white" />
                </div>
                <div class="nav-option-download">
                    <a href="/download" on:click|preventDefault={() => navClicked("download") }>
                        <img src="/img/download-btn.png" alt="download" />
                    </a>
                </div>
            </div>
        </div>

        <div class="navbar-smallscreen">
            <div class="home-button">
                <img class="icon" style="cursor: pointer" src="/img/btn-icon-light.png" alt="logo text" on:click={() => toggleMobile()}/>
            </div>
        </div>



    </nav>
    {#if mobileExpanded}
    <div class="mobile-nav-dropdown" transition:fly="{{ y: -20, duration: 200 }}">
      <div class={clickState == "home" ? "nav-option selected" : "nav-option"}>
        <p on:click|preventDefault={() => navClicked("home") }>HOME</p>
      </div>
      <div class="nav-option">
          <p on:click|preventDefault={() => navClicked("updates") }>UPDATES</p>
      </div>
      <div class="nav-option">
          <p on:click|preventDefault={() => navClicked("discord") }>DISCORD</p>
      </div>
      <div class="nav-option" on:click|preventDefault={() => navClicked("sign up") }>
          <p style="padding-left: 12px; padding-right: 12px">SIGN UP</p>
          <ProfileIcon height="20px" stroke="white" fill="white" />
      </div>
    </div>
    <div class="mobile-nav-dimmer" transition:fade="{{ duration: 200 }}" on:click|preventDefault={() => toggleMobile(false)} />
    {/if}
{/if}

<style lang="scss">
    .navbar {
        display: flex;
        position: fixed;
        top: 0;
        width: 100%;
        height: 64px;
        background-color: rgba(17,17,17,0.75);
        box-shadow: 0 2px 8px black;
        z-index: 6;
    }

    .navbar-widescreen {
      display: flex;
      width: 100%;

      @media screen and (max-width: 790px) {
        display: none;
      }
    }

    .navbar-smallscreen {
      display: flex;
      width: 100%;

      align-items: center;
      justify-content: center;

      @media screen and (min-width: 790px) {
        display: none;
      }
    }

    .mobile-nav-dropdown {
      position: fixed;
      display: flex;
      width: 240px;

      flex-direction: column;

      left: 0;
      top: 64px;
      // height: 64px;
      background-color: rgba(17,17,17,0.97);
      box-shadow: 0 2px 8px black;

      transform: translateX(calc(50vw - 120px));

      margin: 0;
      padding: 0;

      z-index: 5;

      align-items: center;
      justify-content: center;

      @media screen and (min-width: 790px) {
        display: none;
      }
    }

    .mobile-nav-dimmer {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 4;

      @media screen and (min-width: 790px) {
        display: none;
      }
    }

    .navbar-left {
        flex: 7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 70%;
    }
    .navbar-right {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: 60%;
    }
    .home-button {
        width: 64px;
        margin: 64px;
        height: 80%;
    }
    .icon {
        display: block;
        height: 100%;
    }
    .nav-option {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 12px;
        opacity: 0.75;
    }
    .nav-option:hover {
        cursor: pointer;
        opacity: 1;
    }
    .nav-option.selected {
        opacity: 1;
    }
    .nav-option-download {
        display: flex;
        width: 200px;
        height: 100%;
    }
</style>