<script lang="ts">

    import Arrow from "../public/svg/down-arrow-svgrepo-com.svg";
    import * as animateScroll from "svelte-scrollto";
    import App from "./App.svelte";
    
    export let fadefly;
    export let opacity;

</script>

<div id="arrow-container"
    in:fadefly="{{ duration: 1000, maxOpacity: 1 }}"
    on:click={() => animateScroll.scrollTo({element: "#content", duration: 1200, offset: -120})}
    style="opacity: {opacity}">
    <Arrow stroke="white" fill="#FFFFFF55" />
</div>

<style lang="scss">

    @keyframes pulse {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-20px);
        }
    }
    
    #arrow-container {
        transform: translateY(0);
        animation: pulse 1s ease-in-out infinite alternate;
        position: absolute;
        bottom: 24px;
    }
    #arrow-container:hover {
        cursor: pointer;
    }
</style>