<script lang="ts">

    import { onMount } from "svelte";
    import { Circle } from 'svelte-loading-spinners'
    import { getContext } from 'svelte';
    
    const { close } = getContext('simple-modal');

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

    let searchParams = new URLSearchParams(window.location.search);

    let auth: string = searchParams.get("auth");
    let password: string;
    let passwordConfirm: string;

    let authError: string = "";
    let passwordError: string = "";
    let passwordConfirmError: string = "";
    let genericError: string = "";

    let header: HTMLElement;
    let submitting:boolean = false;

    let reset:boolean = false;

    onMount(() => {
        let content = header.parentElement;
        let modalWindow = content.parentElement;
        let modalWindowWrap = modalWindow.parentElement;
        modalWindow.style.width = "30rem";
    });

    async function getEntitlement() {
        await sleep(5000);
        return fetch("/api/entitlements/get-new")
            .then(resp => resp.text());
    }
    
    function onSubmit() {
        if (submitting)
            return;

        // prevent double submit
        submitting = true;
        // clear out error texts
        authError = "";
        passwordError = "";
        passwordConfirmError = "";
        genericError = "";
        
        if (!auth) {
            // todo: status info
            authError = "*Token is required"
            submitting = false;
        }
        else if (password.length < 7) {
            // todo: status info
            passwordError = "*Password must be at least 7 chars";
            submitting = false;
        }
        else if (password != passwordConfirm) {
            // todo: status info
            passwordError = "*Passwords must match";
            passwordConfirmError = "*Passwords must match";
            submitting = false;
        }
        else {
            let safeAuthKey = encodeURIComponent(auth);
            let safePassword = encodeURIComponent(password);

            getEntitlement().then(token => {
                var formData = new FormData();
                formData.append("authKey", safeAuthKey);
                formData.append("password", safePassword);
                formData.append("token", token);
                fetch("/api/account/change-password", { method: "POST", body: formData })
                    .then(resp => resp.text())
                    .then(data =>
                    {
                        // submission is done
                        submitting = false;

                        // ensure data has a value (404, 500, downtime, etc)
                        data = data || "Internal server error";

                        // lower case for simplicity
                        data = data.toLowerCase();

                        if (data.includes("error")) {
                            if (data.includes("internal")) {
                                genericError = "*Server error. Please try again later";
                            }
                            else if (data.includes("bad request")) {
                                genericError = "*Server error. Please try again later";
                            }
                            else if (data.includes("bad password reset token")) {
                                authError = "*Invalid reset token";
                            }
                            else if (data.includes("password length")) {
                                passwordError = "*Password must be at least 7 chars";
                            }
                        }
                        else {
                            reset = true;
                        }
                        console.log(data);
                    });
            });
        }
    }

</script>

<h1 bind:this={header}>Change Password</h1>
<p>
    Use this form to change your password.
</p>
{#if !submitting && !reset}
<form action="/" method="POST">
    <label class="error-label" for="auth" name="auth-error">{authError}</label>
    <input name="auth" bind:value={auth} type="text" placeholder="Reset Token" autocomplete="off" />
    <label class="error-label" for="password" name="password-error">{passwordError}</label>
    <input name="password" bind:value={password} type="password" placeholder="Password" autocomplete="off" />
    <label class="error-label" for="password-confirm" name="password-confirm-error">{passwordConfirmError}</label>
    <input name="password-confirm" bind:value={passwordConfirm} type="password" placeholder="Confirm Password" autocomplete="off" />
    <label class="error-label" for="submit" name="general-error">{genericError}</label>
    <input name="submit" type="submit" value="Change Password" on:click|preventDefault={() => onSubmit() } />
</form>
{:else if submitting}
<div style="display: flex; align-items: center; justify-content: center">
    <Circle size="24" color="#131C18"></Circle>
</div>
{:else if reset}
<div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
    <hr style="border: none; border-top: 1px solid #00000033; width: 90%" />
    <p><strong>All set!</strong> Your password has been changed.</p>
    <input on:click={() => close()} type="submit" value="✔">
</div>
{/if}

<style lang="scss">
    h1 {
        text-align: center;
        font-size: large;
    }
    p {
        text-align: center;
    }
    .error-label {
        align-self: flex-start;
        font-size: 14px;
        padding-left: 12px;
        color: red;
    }
    input {
        border: 1px solid #3c404422;
        margin: 14px;
        padding: 10px 12px;
        color: #3c4044;
        font-size: 16px;
        line-height: 26px;
        width: 90%;
        box-sizing: content-box;
    }
    input[type=submit] {
        cursor: pointer;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #3c404460;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #3c404460;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #3c404460;
    }
</style>