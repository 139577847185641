<script lang="ts">
    import { getContext } from 'svelte';
    const { close } = getContext('simple-modal');
    
    const a = window.document.createElement('a');
    a.href = "/btn-setup.zip";
    a.download = "btn-setup.zip";
    
    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    
    // Remove anchor from body
    document.body.removeChild(a);
</script>

<div class="container">
    <h1>Downloading...</h1>
    <p>We're downloading the patcher now.</p>
    <p>See you in the Nexus!</p>
    <input on:click={() => close()} type="submit" value="Okay!">
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    p {
        margin: 0;
        padding: 0;
    }
    input {
        border: 1px solid #3c404422;
        margin: 14px;
        padding: 10px 12px;
        color: #3c4044;
        font-size: 16px;
        line-height: 26px;
        width: 90%;
        box-sizing: content-box;
    }
    input[type=submit] {
        cursor: pointer;
    }
</style>