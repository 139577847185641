<script lang="ts">
    
    import App from "./App.svelte";

    let laserdiv: HTMLElement;
    export let sleep;


    function loadBg(node, data) {
        // setup black cover
        let black = document.createElement("div");
        black.style.zIndex = "5";
        black.style.background = `black center / cover`;
        black.style.transition = `background-color 0.5s ease`;
        black.style.width = `100%`
        black.style.height = `100%`
        node.appendChild(black);

        // setup main node
        node.style.background = `center / cover`;

        // load image in background
        const img = new Image();
        img.src = data.src;
        img.onload = async () => {
            if (data.delay)
                await sleep(data.delay);
            node.style.backgroundImage = `url("${data.src}")`;
            black.style.backgroundColor = `#FFFFFF00`;
            await sleep(500);
            node.removeChild(black);
            if (data.cb) data.cb();
        }

        return {
            destroy() {}
        };
    }

    // const lasergif = new Image();
    // lasergif.src = "/img/lasers-preview.gif";
    // lasergif.onload = async () => {
    //     await sleep(1500);
    //     laserdiv.style.background = `url("/img/lasers-preview.gif") center / cover`;
    // };

</script>

<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap" rel="stylesheet">
<div class="container">
    <div class="fold">
        <div
            alt="firing lasers"
            class="fold-image"
            use:loadBg={{
                src: "/img/lasers-preview.gif",
                delay: 500
            }}>
        </div>
        <div class="fold-caption">
            <p><strong>PRECISE MOVEMENT</strong></p>
        </div>
    </div>
    <div class="fold">
        <div
            alt="firing lasers"
            class="fold-image"
            use:loadBg={{
                src: "/img/soul-harvest-preview.gif",
                delay: 500
            }}>
        </div>
        <div class="fold-caption">
            <p><strong>BEAUTIFULLY THEMED</strong></p>
        </div>
    </div>
    <div class="fold">
        <div
            alt="smokescreen"
            class="fold-image"
            use:loadBg={{
                src: "/img/assassin-smoke-preview.gif",
                delay: 500
            }}>
        </div>
        <div class="fold-caption">
            <p><strong>POWERFUL CLASS ABILITIES</strong></p>
        </div>
    </div>
</div>


<style lang="scss">
    :root {
        --container-height: 480px;
        --vertical-container-height: var(var(--container-height) * 3);
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 120px);
        height: var(--container-height);

        @media (max-width: 1280px) {
            flex-direction: column;
            width: calc(100% - 32px);
            height: var(--vertical-container-height);
        }
    }
    .fold {
        color: black;
        background: white;
        max-width: 400px;
        width: 100%;
        height: 480px;
        margin: 12px;
        box-shadow: 0px 0px 16px 8px rgb(0 0 0 / 40%);
    }
    .fold-image {
        z-index: 2;
        width: 100%;
        height: 90%;
    }
    .fold-caption {
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    p {
        font-family: 'Roboto', sans-serif;
        margin: 0;
        padding: 0;
    }
</style>